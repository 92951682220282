import React, { ReactNode, UIEvent, useCallback, useEffect, useRef } from 'react'
import CloseIcon from '../../../assets/icons/close.svg'
import ReactDOM from 'react-dom'
import { KeyCode } from '../../../utils/constants/KeyCode'
import styled, { css } from 'styled-components'
import { Body1 } from '../../Typography/Text'
import theme from '../../../utils/theme'

const Container = styled.div<{
    enableScrollLock?: boolean
    position?: 'top' | 'bottom'
    horizontalPosition?: 'left' | 'right'
    showAboveEverything?: boolean
}>`
    position: fixed;
    z-index: ${({ showAboveEverything }) => (showAboveEverything ? 99999999999 : 99)};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: table;
    transition: all 0.3s ease;
    animation: showModalShadow 0.35s 1 ease-in-out forwards;
    @keyframes showModalShadow {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    .wrapper {
        display: table-cell;
        vertical-align: ${({ position }) => position ?? 'middle'};
        ${({ horizontalPosition }) =>
            horizontalPosition
                ? css`
                      float: right;
                      padding-top: 25px;
                  `
                : ''}
    }
    ${({ enableScrollLock }) =>
        enableScrollLock
            ? css`
                  body {
                      overflow-y: hidden;
                  }
              `
            : ''}
`

const ModalContainer = styled.div<{
    maxWidth?: number
    backgroundColor?: string
    hideBorderRadius?: boolean
    allowVerticalOverflow?: boolean
    allowHorizontalOverflow?: boolean
    hideTitle?: boolean
    fullScreen?: boolean
    hasFooter?: boolean
    position?: 'bottom' | 'top'
    horizontalPosition?: 'left' | 'right'
    noPadding?: boolean
    fullNoPadding?: boolean
    headerColor?: string
    allowOverflowVisible?: boolean
    overflowY?: 'auto' | 'visible' | 'hidden'
}>`
    max-width: ${({ maxWidth }) => maxWidth ?? 650}px;
    width: 95%;
    margin: 0px auto;
    border-radius: ${theme.borderRadius.normal};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    animation: showModal 0.35s 1 ease-in-out forwards;
    @keyframes showModal {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .header {
        background-color: ${({ headerColor }) => headerColor ?? '#fff'};
        border-top-left-radius: ${theme.borderRadius.content};
        border-top-right-radius: ${theme.borderRadius.content};
        padding-bottom: 12px;

        .header-line {
            color: white;
            height: 0.5px;
            background-color: rgba(255, 255, 255, 0.15);
            margin: 0 20px;
        }
    }

    .header-grid {
        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 10px;
            gap: 10px;
        }
        display: grid;
        grid-template-columns: 1fr auto;
        place-items: center start;
        width: 100%;
        color: white;
        padding: 20px 20px 0 20px;
        margin-bottom: -1px;
        ${({ hideBorderRadius }) =>
            hideBorderRadius
                ? css`
                      border-radius: 0;
                  `
                : ''}
    }

    .close {
        position: relative;
        bottom: 10px;
        cursor: pointer;
        fill: #3d3e40;
    }

    .body {
        background: ${({ backgroundColor }) => backgroundColor ?? theme.colors.white};
        padding: ${({ noPadding }) => (noPadding ? '0 20px 30px 0px' : '0 20px 30px 20px')};
        max-height: 80vh;
        overflow: hidden;
        border-bottom-left-radius: ${theme.borderRadius.content};
        border-bottom-right-radius: ${theme.borderRadius.content};

        ${({ hideBorderRadius }) =>
            hideBorderRadius
                ? css`
                      border-radius: 0;
                      padding: 0px;
                      overflow: visible;
                  `
                : ''}
        ${({ hideTitle }) =>
            hideTitle
                ? css`
                      border-radius: ${theme.borderRadius.content};
                      max-height: 90vh;
                  `
                : ''}
        ${({ allowVerticalOverflow }) =>
            allowVerticalOverflow
                ? css`
                      overflow-y: visible;
                  `
                : ''}
        ${({ overflowY }) =>
            overflowY
                ? css`
                      overflow-y: ${overflowY};
                  `
                : ''}

        ${({ allowHorizontalOverflow }) =>
            allowHorizontalOverflow
                ? css`
                      overflow-x: auto;
                  `
                : ''}
        ${({ fullScreen }) =>
            fullScreen
                ? css`
                      height: 100vh;
                      max-height: 100vh;
                      padding: 20px 10px;
                      border-radius: 0;
                  `
                : ''}
         ${({ hasFooter }) =>
            hasFooter
                ? css`
                      border-bottom-left-radius: 0;
                      border-bottom-right-radius: 0;
                  `
                : ''}

        ${({ position }) =>
            position === 'bottom'
                ? css`
                      border-radius: ${theme.borderRadius.huge} ${theme.borderRadius.huge} 0 0;
                  `
                : ''}
    ${({ fullScreen }) =>
            fullScreen
                ? css`
                      width: 100%;
                  `
                : ''}
              ${({ fullNoPadding }) =>
            fullNoPadding
                ? css`
                      padding: 0;
                  `
                : ''}
                  ${({ allowOverflowVisible }) =>
            allowOverflowVisible
                ? css`
                      overflow: visible;
                  `
                : ''}
    }
    ${({ hideBorderRadius }) =>
        hideBorderRadius
            ? css`
                  width: 100%;
                  height: 100%;
              `
            : ''}

    ${({ fullScreen }) =>
        fullScreen
            ? css`
                  width: 100%;
              `
            : ''}
`

interface Props {
    show: boolean
    title: string
    onClose?: () => void
    ariaPrefix?: string
    children: ReactNode
    maxWidth?: number
    disableBackdropClick?: boolean
    hideCloseButton?: boolean
    enableScrollLock?: boolean
    backgroundColor?: string
    hideBorderRadius?: boolean
    hideTitle?: boolean
    allowVerticalOverflow?: boolean
    allowHorizontalOverflow?: boolean
    dialogFooter?: ReactNode
    onScrollDown?: () => void
    fullScreen?: boolean
    dialogIcon?: ReactNode
    position?: 'top' | 'bottom'
    horizontalPosition?: 'left' | 'right'
    toggleFullScreen?: boolean
    noPadding?: boolean
    fullNoPadding?: boolean
    showAboveEverything?: boolean
    headerColor?: string
    isDarkTheme?: boolean
    allowOverflowVisible?: boolean
    overflowY?: 'auto' | 'visible' | 'hidden'
}

const Modal = (props: Props) => {
    const modalRef = useRef<HTMLDivElement>(null)

    const onClose = useCallback(() => {
        props.onClose && props.onClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onClose])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.keyCode === KeyCode.escape) {
                props.show && onClose()
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [props.show, onClose])

    const handleMouseDown = useCallback(
        (event: MouseEvent) => {
            if (props.disableBackdropClick) return
            const isClickOutside = !modalRef.current?.contains(event.target as Node)
            if (props.show && isClickOutside) {
                onClose()
            }
        },
        [props.disableBackdropClick, props.show, onClose]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [handleMouseDown])

    function openFullscreen() {
        if (!modalRef.current) return

        if (modalRef.current.requestFullscreen) {
            modalRef.current.requestFullscreen({ navigationUI: 'show' }).catch((err) => {
                console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
            })
        } else if (modalRef.current.webkitRequestFullscreen) {
            /* Safari */
            modalRef.current.webkitRequestFullscreen().catch((err) => {
                console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
            })
        } else if (modalRef.current.msRequestFullscreen) {
            /* IE11 */
            modalRef.current.msRequestFullscreen().catch((err) => {
                console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
            })
        }
    }

    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen().catch((err) => {
                console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
            })
        } else if (document?.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen()
        }
    }
    const toggleFullScreen = useCallback(() => {
        if (!modalRef.current) return

        props.toggleFullScreen ? closeFullscreen() : openFullscreen()
    }, [props.toggleFullScreen])

    useEffect(() => {
        if (props.toggleFullScreen === undefined) return

        toggleFullScreen()
    }, [props.toggleFullScreen, toggleFullScreen])

    useEffect(() => {
        if (!props.enableScrollLock) return

        document.body.style.overflow = props.show ? 'hidden' : 'unset'

        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [props.enableScrollLock, props.show])

    if (typeof window === 'undefined') return null

    if (!props.show) return null

    const onScrollDown = (event: UIEvent<HTMLDivElement>) => {
        if (event.currentTarget.scrollTop > 0) {
            props.onScrollDown && props.onScrollDown()
        }
    }

    return ReactDOM.createPortal(
        <Container
            enableScrollLock={props.enableScrollLock}
            position={props.position}
            horizontalPosition={props.horizontalPosition}
        >
            <div className="wrapper">
                <ModalContainer
                    backgroundColor={props.backgroundColor}
                    ref={modalRef}
                    hideBorderRadius={props.hideBorderRadius}
                    maxWidth={props.maxWidth}
                    hideTitle={props.hideTitle}
                    allowVerticalOverflow={props.allowVerticalOverflow}
                    allowHorizontalOverflow={props.allowHorizontalOverflow}
                    aria-label={`${props.ariaPrefix ?? 'modal'}-dialog`}
                    fullScreen={props.fullScreen}
                    hasFooter={!!props.dialogFooter}
                    position={props.position}
                    noPadding={props.noPadding}
                    fullNoPadding={props.fullNoPadding}
                    headerColor={props.headerColor}
                    allowOverflowVisible={props.allowOverflowVisible}
                    overflowY={props.overflowY}
                >
                    {!props.hideTitle ? (
                        <div className="header">
                            <div className="header-grid">
                                <div className="title">
                                    {props.dialogIcon ? <>{props.dialogIcon}</> : null}
                                    <Body1
                                        fontFamily="var(--font-open-sans)"
                                        fontWeight={500}
                                        color={props.isDarkTheme ? theme.colors.white : theme.colors.subtitleBlack}
                                    >
                                        {props.title}
                                    </Body1>
                                </div>
                                {!props.hideCloseButton ? (
                                    <CloseIcon className="close" onClick={onClose.bind(this)}></CloseIcon>
                                ) : null}
                            </div>
                            <div className="header-line" />
                        </div>
                    ) : null}

                    <div className="body" onScroll={onScrollDown}>
                        {props.children}
                    </div>
                    {props.dialogFooter ? <div className="footer">{props.dialogFooter}</div> : null}
                </ModalContainer>
            </div>
        </Container>,
        document.body
    )
}

export default Modal
