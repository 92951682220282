const colors = {
    knowunityBlue: '#65a0f1',
    knowunityPink: '#97348C',
    knowunityBlueDark: '#4990f3',
    accentBlue: '#2B3F5F',
    accentBlueLighter: '#2B3F5FD9',
    subtitleBlack: '#3D3E40',
    body1Black: '#616366',
    body2Black: '#58636A',
    captionGray: '#C2C7CC',
    outlineGray: '#E3E6EB',
    buttonTextBlack: '#18191A',
    white: '#ffffff',
    black: '#000000',
    headlineDark: '#030712',
    lightCaption: '#C8DFFF',
    caption: '#c2c7cc',
    errorRed: '#f6465b',
    accentOrange: '#FF9078',
    darkerAccentBlue: '#1C2735',
    backgroundColorGray: '#fafafa',
    lightCredit: '#FDF159',
    subjectGreen: '#0fd246',
    subjectBlue: '#1882FF',
    subjectYellow: '#FFA401',
    primitiveYellow500: '#EAB308',
    contentSecondary: '#374151',
    sunlightYellow: '#FACC15',
    grayBackground: '#dfe2e7',
    accentRed: '#FF9078',
    deepSeaBlue: '#2563EB',
    flamingoPink: '#F15599',
    forestGreen: '#15803D',
    snowWhite: '#DBEAFE',
    blueIce: '#C8DFFF',
    lightAccentBlue: '#909bab',
    textError: '#E6423C',
    lightBorder: 'rgba(15, 15, 16, 0.1)',
    paperGrey: '#EFF3F9',
    darkGreyBackground: '#2C2C2D',
    placeholderText: '#4B5563',
    transparent: 'transparent',
    lighterWhite: '#FFFFFFBF',
    darkerDeepSeeBlue: '#263faa',
    lightGray: 'rgba(0, 0, 0, 0.05)',
    lightPurple: '#C4C9EF',
    happyGreen: '#2FA55E',
    violet: '#E7F0F6',
    violetBackground: '#F5FAFC',
    violetDarkerBackground: '#E8EAF8',
    errorBorder: '#CE2500',
    errorBackground: '#FDCCD2',
    plusPlanYellow: '#FFC20E',
    plusPlanYellowBorder: '#FFD650',
    yellowBackground: '#FFF8E1',
    actionGray: '#58636A',
    lightGreen: '#C5E8D0',
    lightRed: '#FDCCD2',
    darkGreen: '#106132',
    bannerBlue: '#448AFF',
    contentBody: '#3A434A',
    primaryGray: '#9b9b9b',
    lightWhite: '#FFFFFFCC',
    transparentWhite: '#FFFFFF1A',
    gradientBlue: '#3079ed',
    aiChatBotBlue: '#2962FF',
    businessBlue: '#2563EB',
    businessGray: '#374151',
    businessLightGreen: '#DCFCE7',
    lightGrayBackground: '#F9F9F9',
    basicBackgroundOverlay: '#0307120D',
    lightInfo: '#EDE9FE',
    backgroundBasic: '#F3F4F6',
    borderOpaque: '#E5E7EB',
    rewardLight: '#FEF9C3',
    redAlertLow: '#FDCCD2',
    lightAccentBorder: '#60A5FA',
    basicContentStateDisabled: '#9CA3AF',
    backgroundSecondary: '#F3F4F6',
    negativeSignal: '#BE123C',
    primitiveGreen: '#F0FDF4',
    primitiveOrange: '#FFF7ED',
    darkThemePrimary: '#172554',
    darkThemeSecondary: '#5A6C9D',
    indigoPurple: '#5335BF',
    darkBlue: '#2A45BD',
    contentPrimary: '#3A434A',
    signalPurple: '#7C3AED',
    lighterGrayBackground: '#1f2937',
    darkerGrayBackground: '#18212D',
    grayScaleSecondary: '#FFFFFFB8',
    backgroundTransparent: '#ffffff1a',
    grayScalePrimary: '#150925',
    grayScalePrimaryTransparent: '#40425d',
    darkThemeBackground: '#141231',
    webappDialogBackground: '#191919',
    darkThemeBackgroundGradient: '#14183a',
}

export enum PrimaryColors {
    deepSeaBlue = '#2563EB',
    flamingoPink = '#F15599',
    forestGreen = '#1E8148',
    snowWhite = '#EFF3F9',
    blueIce = '#C8DFFF',
    knowunityBlue = '#65a0f1',
    accentBlue = '#2B3F5F',
    violetBackground = '#F5FAFC',
    yellowBackground = '#FFF8E1',
    bannerBlue = '#448AFF',
    businessBlue = '#2563EB',
    white = '#FFF',
}

const borderRadius = {
    smallest: '2px',
    small: '5px',
    large: '8px',
    card: '15px',
    normal: '15px',
    message: '20px',
    huge: '30px',
    content: '10px',
    webappCard: '24px',
    webappButton: '44px',
}

const zIndex = {
    cookieBanner: 100,
    navBarMobileExpanded: 95,
    modal: 90,
    tooltipText: 80,
    navBar: 75,
    navBarUserDropdown: 65,
    topBanner: 42,
    homepageBanner: 40,
    player: 40,
    playerIcons: 41,
    FAB: 50,
}

const heights = {
    navbar: '70px',
}

export enum FolderColors {
    violet = '#7852FF',
    pink = '#FF1887',
    yellow = '#FFA401',
    green = '#0FD246',
    blue = '#1882FF',
}

const theme = {
    colors,
    borderRadius,
    zIndex,
    heights,
}

export default theme
