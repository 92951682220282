import styled, { css } from 'styled-components'
import { FirstColumn } from 'components/modules/Footer/Footer.css'
import theme from 'stories/utils/theme'

export const FormGroup = styled.div<{
    readonly noMargin: boolean
    readonly darkTheme?: boolean
    height?: number
    isRelative?: boolean
    isError?: boolean
    disableBorderRadiusBottom?: boolean
}>`
    margin: ${(props) => (props.noMargin ? '0' : '15px 0')};
    margin-top: ${(props) => (props.noMargin ? 0 : 20)}px;

    p {
        font-family: var(--font-inter);
    }
    & textarea {
        min-height: 50px;
        resize: vertical;
    }

    & input:not([type='checkbox']),
    & textarea,
    & select,
    & .fake-input {
        font-family: var(--font-inter);
        font-weight: 400;
        display: block;
        width: 100%;
        margin: 8px 0;
        border: 1px solid ${({ darkTheme }) => (darkTheme ? theme.colors.transparent : `${theme.colors.contentBody}33`)};
        border-radius: ${theme.borderRadius.normal};
        ${(props) =>
            props.disableBorderRadiusBottom
                ? css`
                      border-bottom-left-radius: 0;
                      border-bottom-right-radius: 0;
                  `
                : ''}
        padding: 10px 15px;
        line-height: 1.5;
        min-height: ${({ height }) => height ?? 65}px;
        ${FirstColumn} & {
            min-height: 40px !important;
        }
        color: ${({ darkTheme }) => (darkTheme ? theme.colors.grayScaleSecondary : theme.colors.subtitleBlack)};
        background: ${({ darkTheme }) => (darkTheme ? theme.colors.backgroundTransparent : '#fff')} !important;

        ::placeholder {
            color: ${({ darkTheme }) =>
                darkTheme ? theme.colors.grayScaleSecondary : theme.colors.placeholderText} !important;
            opacity: 1;
            font-family: var(--font-inter);
            font-weight: 500;
            font-size: 16px;
        }

        ${({ isError }) =>
            isError
                ? css`
                      border: 1px solid ${theme.colors.errorBorder};
                      background-color: ${theme.colors.errorBackground};
                  `
                : ''}
    }

    & input:not([type='checkbox']):focus,
    & textarea:focus,
    & select:focus,
    & .fake-input:focus {
        background: ${({ darkTheme }) => (darkTheme ? theme.colors.backgroundTransparent : theme.colors.violetBackground)};
        outline: none !important;
        border-color: ${({ darkTheme }) => (darkTheme ? theme.colors.transparent : theme.colors.deepSeaBlue)};
    }

    & .option {
        input {
            height: auto;
        }
        label {
            display: block !important;
        }
        margin: 0;
    }

    option {
        background-color: ${({ darkTheme }) => (darkTheme ? theme.colors.webappDialogBackground : theme.colors.white)};
    }

    & .fake-input {
        p {
            margin: 0;
        }
    }

    & input:required,
    & input:invalid {
        box-shadow: none;
    }

    @media (max-width: 750px) {
        & input,
        & textarea,
        & select {
            padding: 10px 15px;
        }
    }

    select {
        padding: 18px 20px;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        option:first-of-type {
            display: none;
        }

        ${FirstColumn} & {
            width: 150px;
            padding: 10px;
        }
    }

    label {
        font-weight: 600;
        font-size: 16px;
    }

    label,
    p,
    span,
    a {
        font-family: var(--font-open-sans);
        font-style: normal;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: ${({ darkTheme }) => (darkTheme ? theme.colors.white : theme.colors.body2Black)};
    }
    h5 {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-transform: Uppercase;
        color: #58636a;
        padding-left: 15px;
    }
    .option {
        label {
            display: block !important;
            color: ${({ darkTheme }) => (darkTheme ? theme.colors.white : theme.colors.body2Black)};
        }
        margin: 0;
    }
    p {
        padding: 0;
    }

    label {
        display: block;
    }

    .select-wrapper {
        position: relative;
    }

    ${({ isRelative }) =>
        isRelative
            ? css`
                  position: relative;
              `
            : ''}
`

export const ErrorMessage = styled.p`
    color: var(--red) !important;
    position: relative;
    bottom: 3px;
    font-size: 14px;

    text-align: left !important;
    margin: 0;
`

export const Tooltip = styled.span`
    position: relative;
    display: inline-block;
    margin-left: 5px;

    &:hover span {
        visibility: visible;
    }
`

export const TooltipText = styled.span<{ color?: string; positionOnBottom?: boolean; positionOnRight?: boolean }>`
    visibility: hidden;
    background-color: #c4c9ef;
    color: ${theme.colors.accentBlue};
    padding: 15px;
    p {
        color: ${theme.colors.accentBlue} !important;
    }
    text-align: center;

    border-radius: ${theme.borderRadius.content};

    position: absolute;
    z-index: ${theme.zIndex.tooltipText};

    width: 250px;
    bottom: 125%;
    left: 50%;
    margin-left: -150px;
    ${({ positionOnRight }) =>
        positionOnRight
            ? css`
                  background-color: ${theme.colors.violetDarkerBackground};
                  width: fit-content;
                  bottom: 0;
                  margin-left: 0;
                  left: 100%;
              `
            : ''}
    @media (max-width: 750px) {
        ${({ positionOnBottom }) =>
            positionOnBottom
                ? css`
                      bottom: -375%;
                  `
                : ''}
    }
`
