import React from 'react'
import { User } from 'interfaces/User'
import theme from 'stories/utils/theme'
import styled from 'styled-components'
import NextImage from './NextImage'
import EditIcon from 'assets/icons/edit.svg'
import DefaultProfilePicture from 'assets/app/profile/avatar.png'

const Container = styled.div<{
    readonly size: number
    readonly boxShadow?: boolean
    readonly hasBorder?: boolean
    readonly borderColor?: string
}>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background-size: cover;
    background-position: center;
    img {
        border: ${({ hasBorder, borderColor }) => (hasBorder ? `3px solid ${borderColor ?? '#fff'}` : 'none')};
        box-shadow: ${({ boxShadow }) =>
            boxShadow ? 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' : 'none'};
    }
    border-radius: 100%;
    margin: auto;
    position: relative;
`

const Placeholder = styled(Container)`
    background: #e0edfc;
    color: ${theme.colors.knowunityBlue};
    display: grid;
    place-items: center;
    user-select: none;
    position: relative;
`

const EditBadge = styled.div`
    position: absolute;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${theme.colors.deepSeaBlue};
    border: 2px solid ${theme.colors.white};
    bottom: 5px;
    right: 5px;
    z-index: ${theme.zIndex.FAB};
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: ${theme.colors.white};
    }
`

interface ProfileProps {
    size?: number
    boxShadow?: boolean
    hasBorder?: boolean
    borderColor?: string
    showEditBadge?: boolean
    onEditBadgeClicked?: () => void
}

interface Props extends ProfileProps {
    user: User
}

interface RawProps extends ProfileProps {
    name: string
    profilePicture: string | null
}

export const ProfilePictureRaw = (props: RawProps) => {
    const size = props.size ? props.size : 30
    const showEditBadge = props.showEditBadge && props.onEditBadgeClicked

    const EditButton = () =>
        showEditBadge ? (
            <EditBadge onClick={props.onEditBadgeClicked}>
                <EditIcon />
            </EditBadge>
        ) : null

    // If the user has no profile picture, we show a placeholder with the first letter of the name
    if (!props.profilePicture) {
        return (
            <Placeholder size={size} boxShadow={props.boxShadow} hasBorder={props.hasBorder}>
                <span style={{ fontSize: size * 0.4 }}>{props.name.charAt(0).toUpperCase()}</span>
                <EditButton />
            </Placeholder>
        )
    }

    return (
        <Container size={size} boxShadow={props.boxShadow} hasBorder={props.hasBorder} borderColor={props.borderColor}>
            <NextImage
                style={{ borderRadius: '50%' }}
                src={props.profilePicture}
                alt="user profile picture"
                width={size}
                height={size}
                loading="lazy"
                sizes="10vw"
            />
            <EditButton />
        </Container>
    )
}

const ProfilePicture = ({ user, ...rest }: Props) => {
    return <ProfilePictureRaw name={user.name} profilePicture={user.profilePicture} {...rest} />
}

export default ProfilePicture
