import React from 'react'
import { trackEvent } from 'lib/tracking/analytics-service'
import { AnalyticsEventClientError } from 'lib/constants/AnalyticsEventType'
import { withTranslation, WithTranslation } from 'next-i18next'
import styled from 'styled-components'
import NoFoundImage from 'assets/nothing_found.svg'
import Meta from 'components/modules/SEO/Meta'
import { ButtonText, StandardHeadline } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import { NewButtonLink } from 'components/elements/NewButton'

const Container = styled.section`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;
    gap: 20%;
    @media (max-width: 750px) {
        height: 50vh;
    }
    svg {
        margin: 0 auto;
    }
`

interface ErrorBoundaryProps extends WithTranslation {
    children: React.ReactNode
    componentName?: string
}

interface ErrorBoundaryState {
    hasError: boolean
    error?: Error
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, error }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // Track the error
        trackEvent(AnalyticsEventClientError, {
            value: `${error.message} ${errorInfo.componentStack}`,
        })

        console.error('Client side error:', error, errorInfo)
    }

    render() {
        const { t } = this.props

        if (this.state.hasError) {
            // You can render any fallback UI with translated text
            return (
                <Container>
                    <Meta
                        title={t('common/pageNotFoundPageTitle')}
                        description={t('common/pageNotFoundPageDescription')}
                        noIndex
                    />

                    <StandardHeadline textAlign="center" color={theme.colors.actionGray}>
                        {t('common/apiErrorHandlerErrorOccurredError')}
                    </StandardHeadline>

                    <NoFoundImage />
                    <NewButtonLink href={'/'} borderRadius={theme.borderRadius.normal} width={300}>
                        <ButtonText color={theme.colors.white} fontFamily="var(--font-open-sans)" fontWeight={700}>
                            {t('common/continue')}
                        </ButtonText>
                    </NewButtonLink>
                </Container>
            )
        }

        return this.props.children
    }
}

// Export with HOC wrapper for translation
export default withTranslation(['common'])(ErrorBoundary)
