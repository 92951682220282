import { Language } from 'interfaces/Language'
import { parseLocale } from './localization'

export const getDefaultMetaTitle = (locale?: string) => {
    const parsedLocale = parseLocale(locale)

    switch (parsedLocale.contentLanguageCode) {
        case Language.AmericanEnglish:
        case Language.BritishEnglish:
            return "Let's learn together!"
        case Language.German:
            return 'Schule. Endlich einfach.'
        case Language.French:
            return '#savoirpourtous'
        case Language.Polish:
            return 'Dziel się wiedzą'
        case Language.Italian:
            return 'La Scuola Resa Facile'
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.MexicanSpanish:
            return '¡Juntos estudiamos fácil!'
        case Language.Turkish:
            return 'Çalış, paylaş, yardımlaş.'
        case Language.BrazilianPortuguese:
            return 'Aprenda, compartilhe, ajude.'
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export function getLanguageByDomain(): Language | null {
    const domain = document.location.host
    if (domain === 'knowunity.de' || domain === 'de.knowunity.dev') {
        return Language.German
    } else if (domain === 'knowunity.fr' || domain === 'fr.knowunity.dev') {
        return Language.French
    } else if (domain === 'knowunity.pl' || domain === 'pl.knowunity.dev') {
        return Language.Polish
    } else if (domain === 'knowunity.it' || domain === 'it.knowunity.dev') {
        return Language.Italian
    } else if (domain === 'knowunity.co.uk' || domain === 'gb.knowunity.dev') {
        return Language.BritishEnglish
    } else if (domain === 'knowunity.com' || domain === 'us.knowunity.dev') {
        return Language.AmericanEnglish
    } else if (domain === 'knowunity.es' || domain === 'es.knowunity.dev') {
        return Language.AmericanEnglish
    } else if (domain === 'knowunity.co' || domain === 'co.knowunity.dev') {
        return Language.ColombianSpanish
    } else if (domain === 'knowunity.com.tr' || domain === 'tr.knowunity.dev') {
        return Language.Turkish
    } else if (domain === 'knowunity.ai' || domain === 'ai.knowunity.dev') {
        return Language.AmericanEnglish
    } else if (domain === 'knowunity.cl' || domain === 'cl.knowunity.dev') {
        return Language.ChileanSpanish
    } else if (domain === 'knowunity.ar' || domain === 'ar.knowunity.dev') {
        return Language.ArgentineSpanish
    } else if (domain === 'knowunity.pe' || domain === 'pe.knowunity.dev') {
        return Language.PeruvianSpanish
    } else if (domain === 'knowunity.com.mx' || domain === 'mx.knowunity.dev') {
        return Language.MexicanSpanish
    } else if (domain === 'knowunity.ro' || domain === 'ro.knowunity.dev') {
        return Language.Romanian
    } else if (domain === 'knowunity.gr' || domain === 'gr.knowunity.dev') {
        return Language.Greek
    } else if (domain === 'knowunity.com.br' || domain === 'br.knowunity.dev') {
        return Language.BrazilianPortuguese
    }
    return null
}

export const languageDomains = {
    'knowunity.de': Language.German,
    'knowunity.fr': Language.French,
    'knowunity.pl': Language.Polish,
    'knowunity.it': Language.Italian,
    'knowunity.co.uk': Language.BritishEnglish,
    'knowunity.com': Language.AmericanEnglish,
    'knowunity.es': Language.Spanish,
    'knowunity.co': Language.ColombianSpanish,
    'knowunity.com.tr': Language.Turkish,
    'knowunity.com.mx': Language.MexicanSpanish,
    'knowunity.ar': Language.ArgentineSpanish,
    'knowunity.cl': Language.ChileanSpanish,
    'knowunity.pe': Language.PeruvianSpanish,
    'knowunity.ro': Language.Romanian,
    'knowunity.gr': Language.Greek,
    'knowunity.com.br': Language.BrazilianPortuguese,
}
